import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  HierarchyPointLink,
  HierarchyPointNode,
  HierarchyNode,
  hierarchy,
  tree,
} from 'd3-hierarchy'
import { Selection, BaseType, select, event } from 'd3-selection'
import { xml } from 'd3-fetch'
import { zoom, ZoomBehavior, zoomIdentity, zoomTransform } from 'd3-zoom'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import SpinnerUrl from '../../assets/icons/Spinner.svg'
import { Transition } from 'd3-transition'
import {
  addHighlightIcon,
  updateHighlightIcon,
} from '@components/Hierarchy/Components/HighlightIcon'
import { addAvatar, updateAvatar } from '@components/Hierarchy/Components/Avatar'
import {
  addNameAndTitle,
  updateNameAndTitle,
} from '@components/Hierarchy/Components/NameAndTitle'
import {
  addClickableArea,
  updateClickableArea,
} from '@components/Hierarchy/Components/HoverableClickableArea'
import { addNode, updateNode } from '@components/Hierarchy/Components/Node'
import {
  addHierarchyLink,
  updateHierarchyLink,
} from '@components/Hierarchy/Components/Link'
import {
  addNumberOfSubordinates,
  updateNumberOfSubordinates,
} from '@components/Hierarchy/Components/NumberOfSubordinates'
import IconButton from '@components/ButtonIcon/IconButton'
import Icon from '@components/Icon/Icon'
import { addTag, updateTag } from '@components/Hierarchy/Components/Tag'
import { Absolute, Flex, Spinner, Token } from '@revolut/ui-kit'
import { useTheme } from '@src/styles/theme'
import { useGetReviewGradesMap } from '@src/utils/grades'

export type AddSelection = Selection<
  SVGGElement,
  HierarchyPointNode<HierarchyNodeInterface>,
  SVGGElement,
  HierarchyNodeInterface
>

export type UpdateSelection = Selection<
  BaseType,
  HierarchyPointNode<HierarchyNodeInterface>,
  SVGGElement,
  HierarchyNodeInterface
>

export type HierarchyTransition = Transition<
  BaseType,
  HierarchyNodeInterface,
  null,
  undefined
>

export const HierarchyConsts = {
  cardWidth: 150,
  cardHeight: 80,
  cardRadius: 8,
  zoomStep: 0.3,
  showTags: false,
  subordinatesCircleRadius: 8,
  initialTopPadding: 190,
  gapBetweenLeaves: 30,
  heightOfLinks: 70,
  avatarSize: 48,
}

const ZoomButtons = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 8px;
  right: 0;
  bottom: 32px;
`

const ZoomButton = styled(IconButton)`
  padding: 4px;
  border-radius: 4px;
  background-color: ${Token.color.background};
  color: ${Token.color.greyTone50};
`

const LegendContainer = styled.div`
  pointer-events: none;
  position: absolute;
  display: grid;
  grid-template-rows: auto auto auto auto;
  grid-gap: 10px;
  bottom: 32px;
`

const LegendItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
  grid-gap: 13px;

  & > svg {
    color: ${Token.color.foreground};
  }
`

const CircleWithNumber = styled.div`
  color: ${Token.color.white};
  font-size: 9px;
  margin-left: 2px;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1em;
  border-radius: 50%;
  background-color: ${Token.color.blue};
`

const LegendText = styled.div`
  color: ${Token.color.greyTone50};
`

const Controls = styled.div`
  position: relative;
  width: 100%;
`

const Container = styled.div<{ clickableTitle: boolean }>`
  width: 100%;
  height: 100%;

  & svg {
    cursor: grab;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));

    &:active {
      cursor: grabbing;
    }
  }

  & rect.Card {
    fill: ${Token.color.widgetBackground};
  }

  & rect.Card.Disabled {
    fill: ${Token.color.greyTone10};
  }

  & rect.CardPlaceholder {
    fill: transparent;
    cursor: pointer;

    &:hover {
      stroke: #6262d3;
      stroke-width: 1px;
    }
  }

  & .Spinner {
    color: transparent;

    &.visible {
      color: ${Token.color.foreground};
    }
  }

  & text.AvatarPlaceholderText {
    fill: white;
    text-transform: uppercase;
    pointer-events: none;
    font-size: 20px;
  }

  & rect.AvatarPlaceholder {
    fill: ${Token.color.greyTone20};
    pointer-events: none;
  }

  & text.Title {
    font-size: 12px;
    fill: ${Token.color.foreground};
    pointer-events: ${props => (props.clickableTitle ? 'auto' : 'none')};
    cursor: ${props => (props.clickableTitle ? 'pointer' : 'auto')};

    &:hover {
      text-decoration: ${props => (props.clickableTitle ? 'underline' : 'none')};
    }
  }

  & text.Subtitle {
    font-size: 12px;
    fill: ${Token.color.foreground_50};
    pointer-events: none;
  }

  & g.Hidden {
    opacity: 0;
  }

  & g.Disabled > circle {
    fill: ${Token.color.greyTone50};
  }

  & image {
    pointer-events: none;
  }

  & image.Avatar.Disabled {
    opacity: 0.3;
  }
`

interface HierarchyTreeProps {
  className?: string
  showTags?: boolean
  additionalLegend?: ReactElement
  noWrongFunctional?: boolean
  rootNodeData?: HierarchyNodeInterface
  onChildrenRequest: (node: HierarchyNodeInterface) => Promise<HierarchyNodeInterface[]>
  onNodeTitleClick?: (node: HierarchyNodeInterface) => void
  loading?: boolean
}

const HierarchyTree = ({
  className,
  rootNodeData,
  showTags,
  additionalLegend,
  onChildrenRequest,
  onNodeTitleClick,
  noWrongFunctional,
  loading,
}: HierarchyTreeProps) => {
  const theme = useTheme()
  const container = useRef<HTMLDivElement>(null)
  const [screenSize, setScreenSize] = useState<DOMRect | undefined>()
  const [svg, setSvg] =
    useState<Selection<SVGSVGElement, HierarchyNodeInterface, null, undefined>>()
  const [zoomable, setZoomable] =
    useState<Selection<SVGGElement, HierarchyNodeInterface, null, undefined>>()
  const [g, setG] =
    useState<Selection<SVGGElement, HierarchyNodeInterface, null, undefined>>()

  const gradesMap = useGetReviewGradesMap()

  const zoomBehavior = useMemo<ZoomBehavior<SVGSVGElement, HierarchyNodeInterface>>(
    () =>
      zoom<SVGSVGElement, HierarchyNodeInterface>().extent([
        [0, 0],
        [screenSize?.width || 0, screenSize?.height || 0],
      ]),
    [screenSize],
  )
  let currZoomValue: number = 1

  useEffect(() => {
    if (showTags) {
      HierarchyConsts.cardHeight = 110
      HierarchyConsts.showTags = showTags
      update(rootNodeData)
    } else {
      HierarchyConsts.cardHeight = 80
      HierarchyConsts.showTags = !!showTags
      update(rootNodeData)
    }
  }, [rootNodeData, showTags])

  useEffect(() => {
    /* Fix two finger horizontal scroll */
    select('body').attr('style', 'overscroll-behavior-x: none;')
    return () => {
      select('body').attr('style', '')
    }
  }, [])

  useEffect(() => {
    if (container?.current && !select('.SVG').node()) {
      /* Disable the macbook default browser zoom */
      container.current?.addEventListener?.(
        'wheel',
        e => {
          const { ctrlKey } = e
          if (ctrlKey) {
            e.preventDefault()
          }
        },
        { passive: false },
      )
      const svgContainer = select<HTMLDivElement, HierarchyNodeInterface>(
        container?.current,
      )
        .append('svg')
        .attr('class', 'SVG')
      const zoomableG = svgContainer.append<SVGGElement>('g')
      const initialScreenSize = container?.current?.getBoundingClientRect?.()
      const gContainer = zoomableG
        .append<SVGGElement>('g')
        .attr(
          'transform',
          `translate(${initialScreenSize.width / 2}, ${
            HierarchyConsts.cardHeight + HierarchyConsts.initialTopPadding
          })`,
        )

      setScreenSize(initialScreenSize)
      setSvg(svgContainer)
      setG(gContainer)
      setZoomable(zoomableG)
    }
  }, [rootNodeData, container?.current])

  useEffect(() => {
    if (container?.current && screenSize && svg && zoomable && g) {
      /* Bug it filters the Pinch out of the events */
      zoomBehavior.filter(() => {
        return true
      })

      /* ALL ZOOM MAGIC STARTS FROM HERE */
      zoomBehavior.wheelDelta(() => {
        if (event.type === 'wheel' && event.ctrlKey) {
          return (-event.deltaY * (event.deltaMode ? 120 : 1)) / 100
        }

        return (-event.deltaY * (event.deltaMode ? 120 : 1)) / 1000
      })

      zoomBehavior.on('zoom', function handler() {
        // mouse has wheelDelta bigger than touchpad so, scroll on mouse will just zoom
        // but on touchpad it will move around
        event.sourceEvent?.preventDefault?.()
        if (
          event.sourceEvent?.type === 'wheel' &&
          Math.abs(event.sourceEvent.wheelDelta) < 125
        ) {
          /* In all browsers the pinch event is just wheel event with ctrlKey active
           * well of course except Safari */
          if (event.sourceEvent?.metaKey || event.sourceEvent?.ctrlKey) {
            zoomable.attr('transform', event.transform)
            currZoomValue = event.transform.k
          } else {
            event.sourceEvent.stopPropagation()
            const zoomEvent = event.sourceEvent
            const t = zoomTransform(this)
            zoomBehavior.scaleTo(svg, currZoomValue)
            zoomBehavior.translateBy(
              svg,
              (-zoomEvent.deltaX * 2) / t.k,
              (-zoomEvent.deltaY * 2) / t.k,
            )
          }
        } else {
          zoomable.attr('transform', event.transform)
          currZoomValue = event.transform.k
        }
      })
      update(rootNodeData)

      // Circle mask for Avatar
      const avatarMask = svg.append('defs')
      avatarMask.append('defs').html(`
        <clipPath id="avatar-clip">
          <circle
            id="sd"
            class="medium"
            cy="${HierarchyConsts.avatarSize / 2}px"
            cx="${HierarchyConsts.avatarSize / 2}px"
            r="${HierarchyConsts.avatarSize / 2}px"
          />
        </clipPath>`)
      svg.attr('width', screenSize.width).attr('height', screenSize.height)
      svg.call(zoomBehavior as any)
      svg.on('dblclick.zoom', null)

      /* Spinner */
      xml(SpinnerUrl).then(spinnerSvg => {
        const spinnerNode = spinnerSvg.documentElement.cloneNode(true)
        ;(spinnerNode as Element).classList.add('Spinner')
        ;(g.node() as SVGGElement)?.append(spinnerNode)
      })
    }
  }, [rootNodeData, screenSize, svg, zoomable, g])

  useEffect(() => {
    svg?.style('opacity', loading ? 0 : 1)
  }, [loading])

  const parseToTree = (data: HierarchyNodeInterface) => {
    const root = hierarchy(data, d => d.reports)

    return tree<HierarchyNodeInterface>().nodeSize([
      HierarchyConsts.cardWidth + HierarchyConsts.gapBetweenLeaves,
      HierarchyConsts.cardHeight + HierarchyConsts.heightOfLinks,
    ])(root)
  }

  const update = (data?: HierarchyNodeInterface) => {
    if (!data) {
      return
    }
    if (g && svg && zoomBehavior) {
      const treeData = parseToTree(data)
      const t = g.transition().duration(400) as any // d3 badly typed

      /* Bind Link Data */
      const bindedDataLink = g
        .selectAll<BaseType, HierarchyPointLink<HierarchyNodeInterface>>('.Link')
        .data(treeData.links(), d => `${d.source.data.id}${d.target.data.id}`)
      const enterLink = bindedDataLink.enter()
      const exitLink = bindedDataLink.exit()
      exitLink.remove().attr('rx', HierarchyConsts.cardRadius)

      /* Link */
      addHierarchyLink(enterLink, t, theme)
      updateHierarchyLink(bindedDataLink, t)

      /* Bind Node Data */
      const bindedDataNode = g
        .selectAll<BaseType, HierarchyNode<HierarchyNodeInterface>>('.Main')
        .data(treeData.descendants(), d => `${d.data.id}`)
      const enterGroup = bindedDataNode.enter().append('g').attr('class', 'Main')
      const exitGroup = bindedDataNode.exit()
      exitGroup.remove()

      /* Node */
      addNode(enterGroup, t, noWrongFunctional)
      updateNode(bindedDataNode, t)

      /* Hoverable/clickable area with border */
      addClickableArea(enterGroup, t, update, data, onChildrenRequest)
      updateClickableArea(bindedDataNode, t)

      /* Highlight Icon */
      addHighlightIcon(enterGroup, t, noWrongFunctional)
      updateHighlightIcon(bindedDataNode, t)

      /* Avatar */
      addAvatar(enterGroup, t)
      updateAvatar(bindedDataNode, t)

      /* Name */
      addNameAndTitle(enterGroup, t, onNodeTitleClick)
      updateNameAndTitle(bindedDataNode, t)

      /* Tag */
      addTag(theme, enterGroup, t, gradesMap, HierarchyConsts.showTags)
      updateTag(bindedDataNode, t, HierarchyConsts.showTags)

      /* Number of Subordinates */
      addNumberOfSubordinates(enterGroup, t, theme)
      updateNumberOfSubordinates(bindedDataNode, t, theme)

      /* pan to node */
      bindedDataNode.each(datum => {
        if (datum.data.zoomIn) {
          datum.data.zoomIn = false
          svg
            .transition()
            .duration(400)
            .call(
              zoomBehavior.transform,
              zoomIdentity.translate(
                -datum.x,
                -datum.y + HierarchyConsts.gapBetweenLeaves,
              ),
            )
        }
      })

      enterGroup.each(datum => {
        if (datum.data.zoomIn) {
          datum.data.zoomIn = false
          svg
            .transition()
            .duration(400)
            .call(
              zoomBehavior.transform,
              zoomIdentity.translate(
                -datum.x,
                -datum.y + HierarchyConsts.gapBetweenLeaves,
              ),
            )
        }
      })
    }
  }

  const handleZoom = (step: number) => {
    if (svg && zoomBehavior) {
      /* Can't figure out how to zoom to center */
      svg
        .transition()
        .duration(400)
        .call(
          zoomBehavior.transform,
          zoomTransform(svg.node() as Element).scale(1 + step),
        )
    }
  }

  return (
    <Controls>
      {loading && (
        <Absolute width="100%" height="100%" zIndex={99}>
          <Flex
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color={Token.color.blue} size={32} />
          </Flex>
        </Absolute>
      )}
      <Container
        clickableTitle={!!onNodeTitleClick}
        ref={container}
        className={className}
      />
      <LegendContainer>
        <LegendItem>
          <Icon type="Drag" />
          <LegendText>Click and drag to move around</LegendText>
        </LegendItem>
        <LegendItem>
          <Icon type="ZoomIn" size="small" />
          <LegendText>Hold ⌘ (Command) + Scroll to zoom in/out</LegendText>
        </LegendItem>
        <LegendItem>
          <CircleWithNumber>1</CircleWithNumber>
          <LegendText>Click on employees with this tag to see the level below</LegendText>
        </LegendItem>
        {additionalLegend}
      </LegendContainer>
      <ZoomButtons>
        <ZoomButton onClick={() => handleZoom(HierarchyConsts.zoomStep)}>
          <Icon size="big" type="ZoomIn" />
        </ZoomButton>
        <ZoomButton onClick={() => handleZoom(-HierarchyConsts.zoomStep)}>
          <Icon size="big" type="ZoomOut" />
        </ZoomButton>
      </ZoomButtons>
    </Controls>
  )
}

export default HierarchyTree
